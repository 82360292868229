import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import uuid from 'react-uuid'
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import useNewsSourceListDropDownStyles from './NewsSourceListStyles'

function NewsSourceListMobile({
  unsubscribedList,
  setUnsubscribedList,
  originalList,
  setEdited,
  setOpen,
}) {
  const history = useHistory()
  const classes = useNewsSourceListDropDownStyles()
  const { sources } = useSelector((s) => s.news)

  const selectSource = (source) => {
    history.push({
      pathname: './NewsSourcePage',
      search: `?id=${source.sourceID}&source=${source.name}`,
    })
    setOpen(false)
  }
  function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false
    }
    const sortedArr1 = arr1.slice().sort()
    const sortedArr2 = arr2.slice().sort()
    return sortedArr1.every((element, index) => element === sortedArr2[index])
  }

  const updateList = (subscribe, source) => {
    let newList = []
    if (!subscribe) {
      newList = [...unsubscribedList, source.name]
    } else {
      newList = unsubscribedList.filter((name) => name !== source.name)
    }
    setUnsubscribedList(newList)
    if (arraysEqual(originalList, newList)) {
      setEdited(false)
    }
  }

  const itemClick = (source) => {
    selectSource(source)
  }
  const createNewsSourceListItems = () =>
    sources.map((source) => (
      <ListItem
        key={uuid()}
        button
        component="a"
        className={classes.listItemBody}
        onClick={() => itemClick(source)}
        onKeyDown={(e) => {
          if (e.key === ' ' || e.key === 'Enter') {
            itemClick(source)
            e.preventDefault()
          }
        }}
      >
        <ListItemText className={classes.listItemText} primary={source.name} />
        <ListItemIcon>
          <Checkbox
            edge="end"
            disabled={source.name === 'Rutgers Today'}
            checked={!unsubscribedList.includes(source.name)}
            onChange={() =>
              updateList(unsubscribedList.includes(source.name), source)
            }
          />
        </ListItemIcon>
      </ListItem>
    ))

  return (
    <List component="nav" className={classes.root}>
      {!_.isEmpty(sources) && createNewsSourceListItems()}
    </List>
  )
}

export default NewsSourceListMobile
