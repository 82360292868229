import { makeStyles } from '@material-ui/core/styles'

export const myAlertsStyles = makeStyles((theme) => ({
  buttonbase: {
    width: '100%',
    padding: '0px',
    textAlign: 'left',
    backgroundColor: theme.palette.backgroundColor.ternary,
  },
  emergencyButton: {
    width: '100%',
    padding: '0px',
    textAlign: 'left',
    backgroundColor: theme.palette.base.red,
    color: theme.palette.base.white,
  },
  verticalGrid: {
    margin: '0px',
    width: '100% !important',
  },
  month: {
    marginBottom: '-4px',
  },
  overlay: {
    fontSize: '10px',
  },
  doneIcon: {
    color: theme.palette.base.green,
    fontSize: '50px',
    margin: '20px 0',
  },
  alertActionButton: {
    backgroundColor: theme.palette.backgroundColor.ternary,
    color: theme.palette.primary.main,
  },
  alertMessage: {
    maxHeight: '50vh',
    overflowY: 'auto',
  },
  fullWidth: {
    width: '100% !important',
  },
  cardSpacing: {
    margin: '5px',
    width: 'calc(100% - 10px) !important',
  },
  iconHolder: {
    height: '100% !important',
  },
  icon: {
    height: '100% !important',
    fontSize: '38px',
    borderLeft: '3px solid white',
    borderRadius: '2px',
    borderColor: theme.palette.base.white,
    color: theme.palette.base.white,
  },
  iconHighAlert: {
    height: '100% !important',
    fontSize: '38px',
    borderLeft: `3px solid ${theme.palette.base.red}`,
    borderRadius: '2px',
    color: theme.palette.base.red,
    '&:hover': {
      color: theme.palette.base.red,
    },
  },
  iconMediumAlert: {
    height: '100% !important',
    fontSize: '38px',
    borderLeft: `3px solid #a86500`,
    borderRadius: '2px',
    color: '#a86500',
    '&:hover': {
      color: '#a86500',
    },
  },
  iconLowAlert: {
    height: '100% !important',
    fontSize: '38px',
    borderLeft: `3px solid ${theme.palette.base.blue}`,
    borderRadius: '2px',
    color: theme.palette.base.blue,
    '&:hover': {
      color: theme.palette.base.blue,
    },
  },
  iconITAlertAlert: {
    height: '100% !important',
    fontSize: '38px',
    borderLeft: `3px solid ${theme.palette.base.blue}`,
    borderRadius: '2px',
    color: theme.palette.base.blue,
    '&:hover': {
      color: theme.palette.base.blue,
    },
  },
  iconEmployeeAlertAlert: {
    height: '100% !important',
    fontSize: '38px',
    borderLeft: '3px solid white',
    borderRadius: '2px',
    borderColor: theme.palette.base.white,
    color: theme.palette.base.white,
  },
  emergencyIcon: {
    height: '100% !important',
    fontSize: '38px',
    borderLeft: '3px solid white',
    borderRadius: '2px',
    borderColor: theme.palette.base.white,
    color: theme.palette.base.white,
  },
  redBorder: {
    borderColor: theme.palette.base.red,
  },
  title: {
    textOverflow: 'ellipses',
    color: theme.palette.primary.headerText,
  },
  emergencyTitle: {
    textOverflow: 'ellipses',
    color: theme.palette.base.white,
  },
  titleWidth: {
    width: 'calc(100% - 60px) !important',
  },
  iconColor: {
    color: theme.palette.base.red,
  },
  badgeStyle: {
    width: '100% !important',
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.base.green,
    },
  },
  dialogContent: {
    display: 'flex',
  },
  alertText: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  createDateSpan: {
    fontWeight: 'normal',
    paddingLeft: 125,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  isAcknowledgedSpan: {
    fontWeight: 'normal',
    paddingLeft: 71,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  titleSpan: {
    fontWeight: 'normal',
    paddingLeft: 140,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  linkTitleSpan: {
    fontWeight: 'normal',
    paddingLeft: 109,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  descriptionStyle: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      width: '100%',
    },
  },
  dialogTitleHighAlert: {
    background: theme.palette.base.red,
    color: `${theme.palette.base.white} !important`,
    '&:hover': {
      background: theme.palette.base.red,
      color: `${theme.palette.base.white} !important`,
    },
  },
  dialogTitleMediumAlert: {
    background: '#a86500',
    color: `${theme.palette.base.white} !important`,
    '&:hover': {
      background: '#a86500',
      color: `${theme.palette.base.white} !important`,
    },
  },
  dialogTitleStudentAlertAlert: {
    background: theme.palette.base.red,
    color: `${theme.palette.base.white} !important`,
    '&:hover': {
      background: theme.palette.base.red,
      color: `${theme.palette.base.white} !important`,
    },
  },
  dialogTitleEmployeeAlertAlert: {
    background: theme.palette.base.red,
    color: `${theme.palette.base.white} !important`,
    '&:hover': {
      background: theme.palette.base.red,
      color: `${theme.palette.base.white} !important`,
    },
  },
  dialogTitleLowAlert: {
    background: '#a86500',
    color: `${theme.palette.base.white} !important`,
    '&:hover': {
      background: '#a86500',
      color: `${theme.palette.base.white} !important`,
    },
  },
  dialogTitleITAlertAlert: {
    background: theme.palette.base.blue,
    color: `${theme.palette.base.white} !important`,
    '&:hover': {
      background: theme.palette.base.blue,
      color: `${theme.palette.base.white} !important`,
    },
  },
}))

export default myAlertsStyles
