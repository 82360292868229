import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  dialog: {
    minWidth: '500px',
    width: '70vw',
    maxWidth: '100%',
    height: '70vh',
    maxHeight: '600px',
    padding: '0px',
    backgroundColor: theme.palette.paper.secondary,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100px',
      width: '90vw',
    },
    overflowX: 'hidden',
  },
}))

export default styles
