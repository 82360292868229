/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import {
  DialogActions,
  Button,
  Grid,
  Typography,
  TextField,
  Divider,
  Paper,
  Select,
  MenuItem,
  FormControl,
  Box,
} from '@material-ui/core'
import clsx from 'clsx'

import * as React from 'react'
import { useSelector } from 'react-redux'
import IconPicker from '../../../features/apps/AppComponents/IconPicker/IconPicker'
import {
  setWidgetActive,
  setWidgetActiveLocal,
  selectUserOrder,
  getUsersAddableWidgets,
} from '../../../features/dashboard/dashboardSlice'
import Widget from '../../../features/dashboard/widgets/Widget'
import { drawerClose, addWidgetClose } from '../../navigationSlice'
import { useBreakpointDown } from '../../../theme/helpers/useBreakpointDown'
import Loading from '../../../features/dashboard/widgets/helpers/Loading'
import { getImageFromFirebaseWithHook } from '../../../features/apps/helpers'
import styles from '../../../features/dashboard/DashboardStyles'

function AddWidgets({ dispatch }) {
  const widgets = useSelector((s) => s.dashboard.usersAddableWidgets)
  const classes = styles()
  const usersAddableWidgetsStatus = useSelector(
    (s) => s.dashboard.usersAddableWidgetsStatus,
  )
  console.log(classes)
  const userOrder = useSelector(selectUserOrder)
  const [selectedWidgets, setSelectedWidgets] = React.useState([])
  const [expandedIndicies, setExpandedIndicies] = React.useState([])
  const [selectedIndicies, setSelectedIndicies] = React.useState([])
  const [search, setSearch] = React.useState('')
  const [tabValue, setTabValue] = React.useState(0)
  const small = useBreakpointDown('sm')

  React.useEffect(() => {
    dispatch(getUsersAddableWidgets()).then(() => {
      setSelectedIndicies([])
      setExpandedIndicies([])
      setSelectedWidgets([])
    })
  }, [])

  function checkActive(index) {
    return selectedIndicies.includes(index)
  }

  function checkExpanded(index) {
    return expandedIndicies.includes(index)
  }

  function toggleExpanded(index) {
    if (checkExpanded(index)) {
      setExpandedIndicies(expandedIndicies.filter((entry) => entry !== index))
    } else {
      setExpandedIndicies(expandedIndicies.concat(index))
    }
  }

  function toggleIndex(index) {
    if (checkActive(index)) {
      setSelectedIndicies(selectedIndicies.filter((entry) => entry !== index))
    } else {
      setSelectedIndicies(selectedIndicies.concat(index))
    }
  }

  function toggleWidget(widget, index) {
    if (checkActive(index)) {
      setSelectedWidgets(selectedWidgets.filter((entry) => entry !== widget))
    } else {
      setSelectedWidgets(selectedWidgets.concat(widget))
    }
  }

  const addWidget = () => {
    setSearch('')
    setTabValue(0)
    dispatch(drawerClose())
    dispatch(addWidgetClose())
    let modifiedUserOrder = []
    let selectedWidgetIds = []
    userOrder.forEach((user, index) => {
      modifiedUserOrder[index] = { ...user }
    })
    for (let i = 0; i < selectedIndicies.length; i++) {
      dispatch(setWidgetActiveLocal(selectedWidgets[i]))
      modifiedUserOrder = [selectedWidgets[i], ...modifiedUserOrder]
      selectedWidgetIds = [selectedWidgets[i].id, ...selectedWidgetIds]
      //   const targetIndex = modifiedUserOrder.findIndex((widget) => {
      //     console.log('widget ', widget)
      //     return widget.id === parseInt(selectedWidgets[i].id, 10)
      //   })
      //   const updatedItem = modifiedUserOrder[targetIndex]
      //   let clonedItem = { ...updatedItem }
      //   clonedItem = { ...clonedItem, showOnDashboard: true }
      //   modifiedUserOrder[targetIndex] = clonedItem
    }
    dispatch(setWidgetActive(selectedWidgetIds))
  }

  const handleTextInput = (event) => {
    setSearch(event.target.value)
  }

  const handleChange = (event) => {
    setSearch('')
    setSelectedIndicies([])
    setSelectedWidgets([])
    setTabValue(event.target.value)
  }

  const tabs = [
    'All',
    'Academic',
    'Finance',
    'HR',
    'Campus',
    'Health',
    'Technology',
    'School',
    'Administration',
    'Career',
  ]

  const ids = Array.from(
    new Set(
      widgets
        .filter((widget) => widget.showOnDashboard)
        .map((widget) => widget.category),
    ),
  )

  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={classes.dialogContainer}
      >
        <Grid item>
          <Grid
            item
            container
            direction={small ? 'row' : 'row'}
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Grid item xs className={classes.searchBar}>
              <TextField
                label="Search"
                size="small"
                onChange={handleTextInput}
                value={search}
              />
            </Grid>
            <Grid item>
              <div style={{ width: '5px' }} />
            </Grid>
            <Grid item>
              <FormControl size="small" className={classes.form}>
                <Select
                  value={tabValue}
                  onChange={handleChange}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  }}
                >
                  <MenuItem key={tabs[0]} value={0}>
                    <Typography align="left" className={classes.tabs}>
                      All
                    </Typography>
                  </MenuItem>
                  {ids.map((id) => (
                    <MenuItem key={tabs[id]} value={id}>
                      <Typography align="left" className={classes.tabs}>
                        {tabs[id]}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Widget.Spacer />
          <Divider />
          <Widget.Spacer />
        </Grid>
        <Grid item className={classes.dialogContent}>
          {usersAddableWidgetsStatus === 'fulfilled' ? (
            <WidgetList
              widgets={widgets}
              classes={classes}
              tabValue={tabValue}
              search={search}
              checkActive={checkActive}
              checkExpanded={checkExpanded}
              toggleIndex={toggleIndex}
              toggleWidget={toggleWidget}
              toggleExpanded={toggleExpanded}
            />
          ) : (
            <Loading />
          )}
        </Grid>
        <Grid item>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={addWidget}
              disabled={selectedIndicies.length === 0}
              color="primary"
              variant="outlined"
            >
              {selectedIndicies.length !== 0 ? (
                <>
                  Add {selectedIndicies.length} Widget
                  {selectedIndicies.length > 1 && 's'}
                </>
              ) : (
                <>Select a Widget</>
              )}
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  )
}

function WidgetList({
  widgets,
  classes,
  tabValue,
  search,
  checkActive,
  toggleIndex,
  toggleWidget,
  toggleExpanded,
}) {
  const filterWidget = (widget) =>
    widget.showOnDashboard &&
    (widget.title?.toLowerCase().includes(search?.toLowerCase()) ||
      widget.desc?.toLowerCase().includes(search?.toLowerCase())) &&
    widget.showOnDashboard &&
    (widget.category === tabValue || tabValue === 0)

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={4}
        columns={{ xs: 2, sm: 8, md: 20 }}
        onKeyDown={(event) => {
          if (event.code === 'Space') event.preventDefault()
        }}
      >
        {widgets
          .filter((widget) => filterWidget(widget))
          .map((widget, index) => {
            const active = checkActive(index)
            return (
              <Grid item md={6} key={`${index}-${widget.desc}`}>
                <WidgetCard
                  widget={widget}
                  active={active}
                  classes={classes}
                  toggleExpanded={toggleExpanded}
                  toggleIndex={toggleIndex}
                  toggleWidget={toggleWidget}
                  index={index}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  tabIndex={0}
                />
              </Grid>
            )
          })}
      </Grid>
    </Box>
  )
}

function WidgetCard({
  widget,
  active,
  classes,
  toggleIndex,
  toggleWidget,
  index,
  tabIndex,
}) {
  const [img, setImg] = React.useState('./assets/images/image-not-found.png')
  // const isMobile = CheckIsMobile()
  React.useEffect(() => {
    if (widget.screenshot) {
      getImageFromFirebaseWithHook(widget.screenshot, setImg)
    }
  }, [widget])
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      toggleIndex(index)
      toggleWidget(widget, index)
    }
  }
  return (
    <Grid item key={widget.id}>
      <Paper
        variant="outlined"
        key={widget.id}
        className={clsx(classes.widgetPaper, {
          [classes.selectedPaper]: active,
        })}
        onClick={() => {
          toggleIndex(index)
          toggleWidget(widget, index)
        }}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        <Grid direction="column" className={classes.widgetPaperPadding}>
          <Grid container style={{ position: 'relative' }}>
            <Grid item style={{ textAlign: 'center' }}>
              <img className={classes.widgetImage} src={img} alt="idCard" />
            </Grid>
            <Grid item style={{ position: 'absolute', padding: 10 }}>
              {active && (
                <div className={classes.backgroundDiv}>
                  {active && (
                    <IconPicker
                      iconName="check-circle"
                      color="#cc0033"
                      size="2x"
                    />
                  )}
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            wrap="nowrap"
            justifyContent="space-between"
            className={classes.titlePadding}
          >
            <Grid item>
              <Typography variant="h5">{widget.title}</Typography>
            </Grid>
          </Grid>
          <Divider variant="left" />
          <Widget.Spacer />
          <Typography className={classes.subtitlePadding} variant="body1">
            {widget.desc}
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default AddWidgets
