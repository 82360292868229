import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { sortableHandle } from 'react-sortable-hoc'
import {
  Typography as Text,
  Card,
  Grid,
  CardActionArea,
  IconButton,
} from '@material-ui/core'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import InfoIcon from '@material-ui/icons/Info'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { createIcon, selectFavoritedAppAndUpdate } from '../../helpers'
import AppStyles from './AppStyles'
import { addProfileLogEvent } from '../../../dashboard/widgets/MyProfile/myProfileSlice'
import { showSearchBarContents, updateSearchQuery } from '../../appSlice'

const FavoritesDragHandle = sortableHandle(({ classes }) => (
  <DragHandleIcon className={classes.dragHandleIcon} />
))
function App({
  isMobile = false,
  app,
  isFavoritedList,
  isGuest = false,
  hideInfoIcon,
  enableClick = true,
}) {
  const classes = AppStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const handleFavorited = (e) => {
    e.stopPropagation()
    if (!isGuest) {
      if (process.env.REACT_APP_FIREBASE_ON === 'true') {
        dispatch(
          addProfileLogEvent({
            module: 'Apps',
            object: 'AppTile',
            objectId: 'AppsButton',
            action: 'Click',
            actionContext: app.isFavorited ? 'UnFavorite' : 'Favorite',
            actionContextValue: app.id,
            rcpid: localStorage.getItem('rutgersEduRCPID'),
            timestamp: Date.now(),
            deviceType: isMobile ? 'Mobile' : 'Web',
          }),
        )
      }
      selectFavoritedAppAndUpdate(dispatch, app.id)
    }
  }

  const openLink = () => {
    // className: "MuiButtonBase-root MuiCardActionArea-root makeStyles-appContainerActionArea-2462"
    // if (e.currentTarget.className === "MuiButtonBase-root MuiCardActionArea-root makeStyles-appContainerActionArea-2462")

    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
      dispatch(
        addProfileLogEvent({
          module: 'Apps',
          object: 'AppTile',
          objectId: 'AppsTitle',
          action: 'Click',
          actionContext: 'ExternalClick',
          actionContextValue: app.id,
          rcpid: localStorage.getItem('rutgersEduRCPID'),
          url: app.url,
          timestamp: Date.now(),
          deviceType: isMobile ? 'Mobile' : 'Web',
        }),
      )
    }

    // TODO find new solution this disables all the apps
    if (enableClick) {
      window.open(app.url)
    }
  }

  const getMaintenanceEndDate = () => {
    const endDate = new Date(null)
    endDate.setTime(app.maintenanceEndDate.seconds * 1000)
    return endDate.toLocaleString()
  }

  const showInfo = (e) => {
    e.stopPropagation()
    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
      dispatch(
        addProfileLogEvent({
          module: 'Apps',
          object: 'AppTile',
          objectId: 'AppsButton',
          action: 'Click',
          actionContext: 'ViewInformation',
          actionContextValue: app.id,
          rcpid: localStorage.getItem('rutgersEduRCPID'),
          timestamp: Date.now(),
          deviceType: isMobile ? 'Mobile' : 'Web',
        }),
      )
    }
    dispatch(updateSearchQuery(''))
    dispatch(showSearchBarContents(false))
    history.push({
      pathname: isMobile ? '/ViewAppInfoPageMobile' : './ViewAppInfoPage',
      search: `?appID=${app.id}`,
    })
  }

  return (
    <Card className={classes.appContainer} elevation={3}>
      <CardActionArea
        className={classes.appContainerActionArea}
        disabled={app.inMaintenance}
        onClick={() => openLink()}
      >
        <div className={classes.appContainerBody}>
          <AppIcon classes={classes} app={app} />
          <div className={classes.appInnerContainer}>
            <div className={classes.appInnerContainerBody}>
              <TitleAndBannerContainer
                classes={classes}
                app={app}
                isFavoritedList={isFavoritedList}
              />
              <MaintenanceFavoriteInfoBannerContainer
                hideInfoIcon={hideInfoIcon}
                classes={classes}
                app={app}
                getMaintenanceEndDate={getMaintenanceEndDate}
                handleFavorited={handleFavorited}
                showInfo={showInfo}
              />
            </div>
          </div>
        </div>
      </CardActionArea>
    </Card>
  )
}

const MaintenanceFavoriteInfoBannerContainer = ({
  classes,
  app,
  getMaintenanceEndDate,
  handleFavorited,
  showInfo,
  hideInfoIcon,
}) => (
  <div className={classes.maintenanceFavoriteInfoBannerContainer}>
    {app.inMaintenance ? (
      <div className={classes.maintenanceFavoriteInfoBannerContainerBody}>
        <div className={classes.maintenanceContainer}>
          <Text component="span" className={classes.maintenanceInfo}>
            {`In maintenance til ${getMaintenanceEndDate()}`}
          </Text>
        </div>
      </div>
    ) : (
      <>
        <div>
          <IconButton
            className={classes.iconFocus}
            edge="end"
            aria-label="add/remove favorited building"
            onClick={(e) => handleFavorited(e)}
          >
            {app.isFavorited ? (
              <FavoriteIcon className={classes.selectedFavoriteIconButton} />
            ) : (
              <FavoriteBorderIcon className={classes.iconButtonBorder} />
            )}
          </IconButton>
        </div>
        <div
          style={{
            display: hideInfoIcon ? 'none' : 'block',
            marginRight: '4px',
          }}
        >
          <IconButton
            onClick={(e) => showInfo(e)}
            aria-label="more info"
            className={classes.iconFocus}
          >
            <InfoIcon className={classes.iconButton} />
          </IconButton>
        </div>
      </>
    )}
  </div>
)

const AppIcon = ({ classes, app }) => (
  <div className={classes.iconContainer}>
    <div
      className={classes.icon}
      style={{
        backgroundColor: app.color,
      }}
    >
      {createIcon(app.icon, '3x')}
    </div>
  </div>
)

const TitleAndBannerContainer = ({ classes, app, isFavoritedList }) => (
  <div className={classes.appName}>
    <Grid container>
      <Grid item className={classes.alignLeft}>
        <Text component="span" className={classes.appNameText}>
          {app.title}
        </Text>
      </Grid>

      {app.inMaintenance && (
        <Grid item className={classes.maintenanceBanner}>
          <Text component="span" className={classes.maintenanceText}>
            Out Of Service
          </Text>
        </Grid>
      )}

      {app.isNew && !app.inMaintenance && (
        <Grid item className={classes.isNewBanner}>
          <Text component="span" className={classes.isNewText}>
            New
          </Text>
        </Grid>
      )}

      {app.isFavorited && isFavoritedList && (
        <Grid item className={classes.favoritesDragHandle}>
          <FavoritesDragHandle classes={classes} />
        </Grid>
      )}
    </Grid>
  </div>
)

export default App
