import React from 'react'
import {
  Typography as Text,
  IconButton,
  Grid,
  AppBar,
  Toolbar,
} from '@material-ui/core'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { drawerClose } from '../../../../navigation/navigationSlice'

import AddWidgets from '../../../../navigation/sideNav/customButtons/AddWidgets'
import ReorderWidgetDialog from '../../../../navigation/sideNav/customButtons/ReorderWidgets'
import useManageWidgetModalMobileStyles from './ManageWidgetModalMobileStyles'
import { ManageTabsMobile } from '../ManageTabsMobile/ManageTabsMobile'

function ManageWidgetModalMobile() {
  const dispatch = useDispatch()
  const classes = useManageWidgetModalMobileStyles()
  console.log(classes)
  const history = useHistory()

  const updateOrder = () => {
    dispatch(drawerClose())
  }

  const managePanels = ['Add a widget', 'Reorder Widgets']

  const exitPage = () => {
    history.goBack()
  }
  return (
    <div className={classes.container}>
      <AppBar
        className={classes.appBar}
        position="fixed"
        elevation={2}
        style={{ backgroundColor: '#cc0033' }}
      >
        <Toolbar className={classes.container}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item sm={4}>
              <IconButton onClick={() => exitPage()}>
                <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
              </IconButton>
            </Grid>
            <Grid item sm={4}>
              <Text className={classes.pageTitle}>Manage Widgets</Text>
            </Grid>
          </Grid>
          <Grid item sm={4}>
            <></>
          </Grid>
        </Toolbar>
      </AppBar>
      <div
        style={{
          paddingTop: 55,
          maxHeight: 'calc(100vh - 110px)',
          height: '100%',
        }}
      >
        <ManageTabsMobile
          tabs={managePanels}
          updateOrder={updateOrder}
          TabOne={<AddWidgets dispatch={dispatch} />}
          TabTwo={<ReorderWidgetDialog classes={classes} />}
        ></ManageTabsMobile>
      </div>
    </div>
  )
}

export default ManageWidgetModalMobile
