import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './features/login/loginSlice'
import dashboardReducer from './features/dashboard/dashboardSlice'
import myAppsReducer from './features/apps/appSlice'
import campusPassReducer from './features/dashboard/widgets/CampusPass/campusPassSlice'
import jobOpportunitiesReducer from './features/dashboard/widgets/JobOpportunities/jobOpportunitiesSlice'
import gradeReducer from './features/dashboard/widgets/Grade/gradeSlice'
import courseScheduleReducer from './features/dashboard/widgets/CourseSchedule/courseScheduleSlice'
import courseRegistrationReducer from './features/dashboard/widgets/CourseRegistration/courseRegistrationSlice'
import libraryQuickSearchReducer from './features/dashboard/widgets/LibraryQuickSearch/libraryQuickSearchSlice'
import myPaycheckReducer from './features/dashboard/widgets/Paycheck/myPaycheckSlice'
import myDegreeReducer from './features/dashboard/widgets/MyDegree/myDegreeSlice'
import navigationReducer from './navigation/navigationSlice'
import didYouKnowReducer from './features/dashboard/widgets/DidYouKnow/didYouKnowSlice'
import myMoneyReducer from './features/dashboard/widgets/MyMoney/myMoneySlice'
import settingsReducer from './features/settings/settingsSlice'
import myProfileReducer from './features/dashboard/widgets/MyProfile/myProfileSlice'
import myExpensesReducer from './features/dashboard/widgets/MyExpenses/myExpensesSlice'
import myWellnessReducer from './features/dashboard/widgets/MyWellness/myWellnessSlice'
import myDiningReducer from './features/dashboard/widgets/MyDining/myDiningSlice'
import facultyStaffReducer from './features/dashboard/widgets/FacultyStaffCenter/facultyStaffCenterSlice'
import studentServiceCenterReducer from './features/dashboard/widgets/StudentServiceCenter/studentServiceCenterSlice'
import mapsReducer from './features/maps/sections/Map/redux/myMapsSlice'
import buildingsReducer from './features/maps/sections/Buildings/redux/myBuildingsSlice'
import newsReducer from './features/news/newsSlice'
import filterdNewsReducer from './features/news/Pages/NewsSourcePage/NewsSourcePageSlice'
import myCourseActivityReducer from './features/dashboard/widgets/MyCourseActivity/MyCourseActivitySlice'
import myBenefitsReducer from './features/dashboard/widgets/MyBenefits/MyBenefitsSlice'
import myAlertsReducer from './features/dashboard/widgets/MyAlerts/MyAlertsSlice'
import masonGrossReducer from './features/dashboard/widgets/MasonGross/MasonGrossSlice'
import gradFundReducer from './features/dashboard/widgets/GradFund/GradFundSlice'
import scarletMailReducer from './features/dashboard/widgets/MyEmailScarletMail/MyEmailScarletMailSlice'
import itServiceCenterReducer from './features/dashboard/widgets/ITServiceCenter/itServiceCenterSlice'
import RUInfoReducer from './features/dashboard/widgets/RUInfo/RUInfoSlice'
import jobsInternshipsReducer from './features/dashboard/widgets/JobsInternships/JobsInternshipsSlice'
import TechGuideReducer from './features/dashboard/widgets/TechGuide/TechGuideSlice'
import enrollmentPathwayReducer from './features/dashboard/widgets/EnrollmentPathway/enrollmentPathwaySlice'
import FinancialAidReducer from './features/dashboard/widgets/FinancialAid/FinanialAidSlice'
import myTeachingScheduleReducer from './features/dashboard/widgets/MyTeachingSchedule/MyTeachingScheduleSlice'
import myRUConnectReducer from './features/dashboard/widgets/MyEmailRUConnect/myEmailRUConnectSlice'
import careerServicesReducer from './features/dashboard/widgets/CareerServices/CareerServicesSlice'
import CareerServicesEventsReducer from './features/dashboard/widgets/CareerServicesEvents/CareerServicesEventsSlice'
import AdminPageReducer from './features/admin/adminPageSlice'
import RUDOTSServiceCenterReducer from './features/dashboard/widgets/RUDOTSServiceCenter/RUDOTSServiceCenterSlice'
import flexibleWorkReducer from './features/dashboard/widgets/FlexibleWork/FlexibleWorkSlice'
import universityMessagesReducer from './features/dashboard/widgets/UniversityMessages/UniversityMessagesSlice'
import policyReducer from './features/dashboard/widgets/Policy/policySlice'

export const store = configureStore({
  reducer: {
    login: loginReducer,
    dashboard: dashboardReducer,
    myApps: myAppsReducer,
    campusPass: campusPassReducer,
    myDegree: myDegreeReducer,
    jobOpportunities: jobOpportunitiesReducer,
    grade: gradeReducer,
    courseSchedule: courseScheduleReducer,
    courseRegistration: courseRegistrationReducer,
    libraryQuickSearch: libraryQuickSearchReducer,
    myPaycheck: myPaycheckReducer,
    navigation: navigationReducer,
    didYouKnow: didYouKnowReducer,
    myMoney: myMoneyReducer,
    settings: settingsReducer,
    myProfile: myProfileReducer,
    myExpenses: myExpensesReducer,
    myWellness: myWellnessReducer,
    myDining: myDiningReducer,
    facultyStaffCenter: facultyStaffReducer,
    studentServiceCenter: studentServiceCenterReducer,
    itServiceCenter: itServiceCenterReducer,
    maps: mapsReducer,
    buildings: buildingsReducer,
    news: newsReducer,
    filteredNews: filterdNewsReducer,
    myCourseActivity: myCourseActivityReducer,
    myBenefits: myBenefitsReducer,
    myAlerts: myAlertsReducer,
    masonGross: masonGrossReducer,
    gradFund: gradFundReducer,
    scarletMail: scarletMailReducer,
    RUInfo: RUInfoReducer,
    jobsInternships: jobsInternshipsReducer,
    techGuide: TechGuideReducer,
    enrollmentPathway: enrollmentPathwayReducer,
    financialAid: FinancialAidReducer,
    myTeachingSchedule: myTeachingScheduleReducer,
    ruConnect: myRUConnectReducer,
    careerServices: careerServicesReducer,
    careerServicesEvents: CareerServicesEventsReducer,
    adminPage: AdminPageReducer,
    RUDOTSServiceCenter: RUDOTSServiceCenterReducer,
    flexibleWork: flexibleWorkReducer,
    universityMessages: universityMessagesReducer,
    policy: policyReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
})
