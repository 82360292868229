/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Box, useTheme, Typography } from '@material-ui/core'
import { a11yProps } from '../../../dashboard/DashboardComponents/TabPanel/TabPanel'
import { myNewsPanelStyles } from './NewsTabsMobileStyles'
import {
  RutgersTabs,
  RutgersTab,
} from '../../../dashboard/DashboardComponents/Tabs/RutgersTabs'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Typography component="span">{children}</Typography>}
    </div>
  )
}

export function NewsTabsMobile(props) {
  const { tabs, updateOrder, TabOne, TabTwo } = props
  const classes = myNewsPanelStyles()
  const [tabValue, setTabValue] = React.useState(0)
  const theme = useTheme()

  const handleChange = (event, newValue) => {
    updateOrder()
    setTabValue(newValue)
  }

  return (
    <Box className={classes.rootTabBox}>
      <RutgersTabs
        value={tabValue}
        onChange={handleChange}
        className={classes.rootTabContainer}
        aria-label="my-news tabs"
        variant="fullWidth"
      >
        {tabs.map((tab, index) => (
          <RutgersTab
            key={index}
            label={tab}
            className={
              tabValue === index ? classes.activeTab : classes.inactiveTab
            }
            {...a11yProps(index)}
          />
        ))}
      </RutgersTabs>

      <TabPanel value={tabValue} index={0} dir={theme.direction}>
        {TabOne}
      </TabPanel>

      <TabPanel value={tabValue} index={1} dir={theme.direction}>
        {TabTwo}
      </TabPanel>
    </Box>
  )
}
