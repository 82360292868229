import { makeStyles } from '@material-ui/core/styles'

export const manageStyles = makeStyles((theme) => ({
  rootTabContainer: {
    width: '100%',
  },
  activeTab: {
    display: 'flex',
    color: theme.palette.base.red,
    background: theme.palette.paper.primary,
    minWidth: 30,
    width: 30,
    fontSize: 14,
    paddingLeft: 0,
    paddingRight: 0,
  },
  inactiveTab: {
    display: 'flex',
    background: theme.palette.paper.secondary,
    minWidth: 30,
    width: 30,
    fontSize: 12,
    paddingLeft: 0,
    paddingRight: 0,
  },
}))
