/* eslint-disable no-unused-vars */
import { Button, Grid, DialogActions, Snackbar } from '@material-ui/core'
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'
import Alert from '@material-ui/lab/Alert'
import useSound from 'use-sound'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'

import Container from './dnd-kit/Container'
import { Item } from './dnd-kit/SortableItem'

import {
  selectWidgets,
  updateWidgets,
  selectUserOrder,
} from '../../../features/dashboard/dashboardSlice'
import { drawerClose } from '../../navigationSlice'
import styles from './ReorderWidgetStyles'
import './helper.css'
import trashSfx from './trash.mp3'

function ReorderWidgets() {
  const classes = styles()
  const dispatch = useDispatch()

  // This is the main button component that will spawn the dialog
  // Everything past here is
  return (
    <>
      <ReorderWidgetDialog dispatch={dispatch} classes={classes} />
    </>
  )
}

function ReorderWidgetDialog({ dispatch, classes }) {
  const widgets = useSelector(selectWidgets)
  const userOrder = useSelector(selectUserOrder)
  const [dashboardWidgets, setDashboardWidgets] = React.useState([])
  const [edited, setEdited] = React.useState(false)
  const [playTrashSound] = useSound(trashSfx)

  const [activeId, setActiveId] = React.useState()

  const sensors = useSensors(
    useSensor(PointerSensor, {
      delay: 5,
    }),
  )

  const updateOrder = () => {
    dispatch(updateWidgets(dashboardWidgets))
    dispatch(drawerClose())
  }

  const [snackBarOpen, setSnackbarOpen] = React.useState(false)

  const handleSnackbarClose = () => setSnackbarOpen(false)

  React.useEffect(() => {
    const mapped = widgets.map((widget) => {
      const element = userOrder
        ? userOrder.find((inner) => parseInt(widget.id, 10) === inner.id)
        : []
      const elementManuallyMoved = element ? element.manuallyMoved : false
      return {
        ...widget,
        // manuallyMoved: elementManuallyMoved,
      }
    })
    setDashboardWidgets(mapped)
  }, [])

  const handleDragStart = (event) => {
    const { active } = event
    const { id } = active
    const draggingWidget = dashboardWidgets.find(
      (widget) => parseInt(widget.id, 10) === parseInt(id, 10),
    )
    setActiveId(draggingWidget)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event
    // eslint-disable-next-line no-shadow
    const { id: activeId } = active
    const { id: overId } = over
    const oldIndex = dashboardWidgets.findIndex(
      (widget) => parseInt(widget.id, 10) === parseInt(activeId, 10),
    )
    const newIndex = dashboardWidgets.findIndex(
      (widget) => parseInt(widget.id, 10) === parseInt(overId, 10),
    )
    // need a copy of the dashboardWidgets[]
    const copy = dashboardWidgets.map((dashboardWidget) => ({
      ...dashboardWidget,
    }))
    copy[oldIndex].manuallyMoved = Date.now()
    const newArray = arrayMove(copy, oldIndex, newIndex)
    setEdited(true)
    setActiveId(null)
    setDashboardWidgets(newArray)
  }

  const onSubscribe = (id) => {
    const oldIndex = dashboardWidgets.findIndex(
      (widget) => parseInt(widget.id, 10) === parseInt(id, 10),
    )
    const updatedItem = dashboardWidgets[oldIndex]
    if (updatedItem.lock) {
      setSnackbarOpen(true)
      return
    }
    // let clonedObj = { ...updatedItem }
    // clonedObj = { ...clonedObj, showOnDashboard: false }
    // dashboardWidgets[oldIndex] = clonedObj
    const newDashboardWidgets = dashboardWidgets.filter(
      (widget) => widget.id !== id,
    )
    setDashboardWidgets(newDashboardWidgets)
    // update local store
    dispatch(updateWidgets(newDashboardWidgets))
    playTrashSound()
  }

  const onDelete = (id) => {
    const oldIndex = dashboardWidgets.findIndex(
      (widget) => parseInt(widget.id, 10) === parseInt(id, 10),
    )
    const updatedItem = dashboardWidgets[oldIndex]
    if (updatedItem.lock) {
      setSnackbarOpen(true)
      return
    }
    // let clonedObj = { ...updatedItem }
    // clonedObj = { ...clonedObj, showOnDashboard: false }
    // dashboardWidgets[oldIndex] = clonedObj
    const newDashboardWidgets = dashboardWidgets.filter(
      (widget) => widget.id !== id,
    )
    setDashboardWidgets(newDashboardWidgets)
    // update local store
    dispatch(updateWidgets(newDashboardWidgets))
    playTrashSound()
  }

  return (
    <>
      <Grid container direction="column" wrap="nowrap">
        <Grid item className={classes.dialogContent}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
              }}
            >
              <Container
                id="active"
                items={dashboardWidgets.filter(
                  (widget) => widget.showOnDashboard,
                )}
                activeId={activeId}
                onSubscribe={onSubscribe}
                onDelete={onDelete}
              />
            </div>
            <DragOverlay modifiers={[restrictToParentElement]}>
              {activeId ? <Item widget={activeId} overlay /> : null}
            </DragOverlay>
            <Snackbar
              autoHideDuration={1000}
              open={snackBarOpen}
              onClose={handleSnackbarClose}
            >
              <Alert severity="error">Can&rsquo;t delete this widget!</Alert>
            </Snackbar>
          </DndContext>
        </Grid>
        <Grid item>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={updateOrder}
              variant="outlined"
              disabled={!edited}
              color="primary"
            >
              Set Order
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  )
}

export default ReorderWidgets
export { ReorderWidgetDialog }
