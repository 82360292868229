/* eslint-disable no-unused-vars */
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  useTheme,
  Grid,
  Tooltip,
  Typography,
  IconButton,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
// PUT ADD WIDGETS AND REORDER WIDGETS INSIDE OF MANAGE WIDGETS FOLDER.
import AddWidgets from '../../../../navigation/sideNav/customButtons/AddWidgets'
// import ReorderWidgets from '../../../../navigation/sideNav/customButtons/ReorderWidgets'
import {
  addWidgetOpen,
  selectAddWidgetIsOpen,
  addWidgetClose,
} from '../../../../navigation/navigationSlice'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import Modal from '../../../../CommonComponents/Modal/Modal'
import ReorderWidgetDialog from '../../../../navigation/sideNav/customButtons/ReorderWidgets'
import { RutgersTab, RutgersTabs } from '../Tabs/RutgersTabs'
import { TabPanel, a11yProps } from '../TabPanel/TabPanel'
import styles from './ManageWidgetsStyles'
function ManageWidgets() {
  const classes = styles()
  const dispatch = useDispatch()
  const isMobile = CheckIsMobile()
  const his = useHistory()
  const openDialog = useSelector(selectAddWidgetIsOpen)
  const setOpen = () => {
    if (isMobile) {
      console.log('RUTGERS')
      his.push({
        pathname: './ManageWidgetModalMobile',
      })
    } else {
      dispatch(addWidgetOpen())
    }
  }
  const handleClose = () => {
    dispatch(addWidgetClose())
  }
  return (
    <>
      <Tooltip
        title={<Typography variant="subtitle2">Manage Widgets</Typography>}
        placement="top"
        arrow
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="widgets modal"
          onClick={() => setOpen()}
        >
          <AddIcon className={classes.appBarIcon} />
        </IconButton>
      </Tooltip>
      <Modal
        open={openDialog}
        dialogTitle="Customize myDashboard"
        onCloseDialog={handleClose}
        dialogContent={
          <ManageWidgetsContent
            openDialog={openDialog}
            setOpen={setOpen}
            dispatch={dispatch}
            classes={classes}
          />
        }
      />
    </>
  )
}

function ManageWidgetsContent({ openDialog, setOpen, dispatch, classes }) {
  const [modalTabValue, setModalTabValue] = React.useState(0)
  const handleModalTabChange = (event, newValue) => {
    setModalTabValue(newValue)
  }
  const theme = useTheme()
  const manageTabs = ['Add a Widget', 'Reorder Widgets']

  return (
    <>
      <Grid>
        <RutgersTabs
          value={modalTabValue}
          onChange={handleModalTabChange}
          aria-label="my-dashboard tabs"
          variant="fullwidth"
          className={classes.rootTabContainer}
        >
          {manageTabs.map((tab, index) => (
            <RutgersTab
              key={tab}
              label={tab}
              className={
                modalTabValue === index
                  ? classes.activeTab
                  : classes.inactiveTab
              }
              {...a11yProps(index)}
            ></RutgersTab>
          ))}
        </RutgersTabs>

        <TabPanel value={modalTabValue} index={0} dir={theme.direction}>
          <AddWidgets dispatch={dispatch} />
        </TabPanel>
        <TabPanel value={modalTabValue} index={1} dir={theme.direction}>
          <ReorderWidgetDialog dispatch={dispatch} />
        </TabPanel>
      </Grid>
    </>
  )
}

export default ManageWidgets
