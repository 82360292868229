import React from 'react'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton, Typography } from '@material-ui/core'
import styles from './ModalStyles'
function Modal({ open, dialogTitle, dialogContent, onCloseDialog }) {
  const classes = styles()

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <DialogTitle>
        <Grid
          container
          direction="row"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Typography variant="h5">{dialogTitle}</Typography>
          <IconButton aria-label="close" onClick={onCloseDialog}>
            <CloseIcon style={{ color: '#DD3333' }} />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers className={classes.dialog}>
        {dialogContent}
      </DialogContent>
    </Dialog>
  )
}

export default Modal
